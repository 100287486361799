<template>
    <div>
        <detailComponent :versionId="otherParams"/>

        <Divider dashed><h3>审批详情</h3></Divider>
        <history :type="11" :relateId="otherParams" ></history>
    </div>
</template>

<script>
import DetailComponent from '@/components/statementChange/DetailComponent'
import History from '@/components/approval/History'

export default {
  props: {
    baseId: {
      type: Number,
      required: true
    },
    otherParams: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      id: this.baseId
    }
  },
  components: {
    DetailComponent, History
  }
}
</script>
